import React, { useState, useEffect, useContext } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { UserContext } from '/src/contexts/UserContext'
import './styles.scss'

const PageHeader = loadable(() => import('/src/components/Structure/PageHeader'))
const ShareIcons = loadable(() => import('/src/components/Basic/ShareIcons'))
const SubmitButton = loadable(() => import('/src/components/Form/SubmitButton'))

const ShareCodeHeader = ({ pageTitle, description, shareUrl, addSnow }) => {
    const [user] = useContext(UserContext)

    const [referUrl, setReferUrl] = useState('')
    const [isSubmitted, setSubmitted] = useState(false)

    useEffect(() => {
        setReferUrl(`${shareUrl.url}${user.plid}`)
    }, [shareUrl, user])

    const copyToClipboard = async () => {
        await navigator.clipboard.writeText(referUrl).then(() => {
            setSubmitted(true)
        })
    }

    const shareArea = (
        <div className="row">
            <div className="col-md-10 offset-md-1 col-lg-6 offset-lg-3">
                <div className="c-share-code-header__label">Your unique referral code to share is:</div>

                <div className="c-share-code-header__url-container">
                    <span className="c-share-code-header__url">{referUrl}</span>
                    <SubmitButton
                        initialText={'Copy'}
                        sentText={'Copied!'}
                        isSubmitted={isSubmitted}
                        onClick={copyToClipboard}
                        className={'c-share-code-header__button'}
                    />
                </div>

                <ShareIcons referUrl={referUrl} />
            </div>
        </div>
    )

    return (
        <PageHeader
            variant={'centered'}
            pageTitle={pageTitle}
            description={description}
            node={shareArea}
            addSnow={addSnow}
        />
    )
}

ShareCodeHeader.propTypes = {
    pageTitle: PropTypes.string,
    description: PropTypes.string,
    shareUrl: PropTypes.shape({
        url: PropTypes.string.isRequired
    }).isRequired,
    addSnow: PropTypes.bool
}

ShareCodeHeader.defaultProps = {
    pageTitle: 'Page Title',
    description: '<p>I am a description</p>',
    shareUrl: {
        url: 'https://www.protectline.co.uk/'
    },
    addSnow: false
}

export default ShareCodeHeader

export const query = graphql`
    fragment ShareCodeHeaderForPageBuilder on WpPage_Acfpagebuilder_Layouts_ShareCodeHeader {
        fieldGroupName
        description
        shareUrl {
            url
        }
    }
`
